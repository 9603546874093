<template>
  <div class="wrapper">
     <div class="main">
      <div class="intro section section-cards" >
        <br>
        <br>
        <div class="container layout-mod" >
          <h1 class="title"><img :src="title_games"  style="height: 52px;"  alt="story"></h1>
          <div class="steps-wrap md-layout">
              <div class="md-layout-item md-size-33 md-small-size-100">
                  <div class="plan">
                    <span class="coloured-img plan-4">
                        <span class="appmarket">
                          <a v-if="base.isDomestic"
                            href="#"
                            @click="showDomesticModal"
                            >
                            <img :src="pre_order_page_new_home_btn" alt="">
                          </a>
                          <router-link v-else
                                      to="/games/match"
                                      class="pre-order-game-intro-links">
                                      <img :src="pre_order_page_new_home_btn" alt="">
                          </router-link>
                          <a v-if="base.isDomestic"
                             href="#"
                            @click="showDomesticModal"
                          >
                              <img :src="pre_order_page_new_google_btn" alt="">
                          </a>
                          <a v-else
                              href="https://play.google.com/store/apps/details?id=com.wemadeplay.anipangmatch"
                          >
                              <img :src="pre_order_page_new_google_btn" alt="">
                          </a>
                          <a v-if="base.isDomestic"
                             href="#"
                            @click="showDomesticModal"
                          >
                              <img :src="pre_order_page_new_app_btn" alt="">
                          </a>
                          <a v-else
                              href="https://apps.apple.com/app/id1638247457"
                          >
                              <img :src="pre_order_page_new_app_btn" alt="">
                          </a>
                        </span>
                        <img v-if="locale === 'ko'" :src="title_games_lineup_1.ko" alt=""/>
                        <img v-else :src="title_games_lineup_1.en" alt=""/>
                    </span>
                  </div>
              </div>
              <div class="md-layout-item md-size-33 md-small-size-100">
                  <div class="plan">
                    <span class="coloured-img plan-3">
                        <span class="appmarket">
                          <a v-if="base.isDomestic"
                            href="#"
                            @click="showDomesticModal"
                            >
                            <img :src="pre_order_page_new_home_btn" alt="">
                          </a>
                          <router-link v-else
                                      to="/games/blast"
                                      class="pre-order-game-intro-links">
                                      <img :src="pre_order_page_new_home_btn" alt="">
                          </router-link>
                          <a v-if="base.isDomestic"
                             href="#"
                            @click="showDomesticModal"
                          >
                              <img :src="pre_order_page_new_google_btn" alt="">
                          </a>
                          <a v-else
                              href="https://play.google.com/store/apps/details?id=com.wemadeplay.anipangblast"
                          >
                              <img :src="pre_order_page_new_google_btn" alt="">
                          </a>
                          <a v-if="base.isDomestic"
                             href="#"
                            @click="showDomesticModal"
                          >
                              <img :src="pre_order_page_new_app_btn" alt="">
                          </a>
                          <a v-else
                              href="https://apps.apple.com/app/id1640186076"
                          >
                              <img :src="pre_order_page_new_app_btn" alt="">
                          </a>
                        </span>
                        <img v-if="locale === 'ko'" :src="title_games_lineup_2.ko" alt=""/>
                        <img v-else :src="title_games_lineup_2.en" alt=""/>
                    </span>
                  </div>
              </div>
              <div class="md-layout-item md-size-33 md-small-size-100">
                  <div class="plan">
                    <span class="coloured-img plan-2">
                        <span class="appmarket">
                          <a v-if="base.isDomestic"
                            href="#"
                            @click="showDomesticModal"
                            >
                            <img :src="pre_order_page_new_home_btn" alt="">
                          </a>
                          <router-link v-else
                                      to="/games/coins"
                                      class="pre-order-game-intro-links">
                                      <img :src="pre_order_page_new_home_btn" alt="">
                          </router-link>
                          <a v-if="base.isDomestic"
                             href="#"
                            @click="showDomesticModal"
                          >
                              <img :src="pre_order_page_new_google_btn" alt="">
                          </a>
                          <a v-else
                              href="https://play.google.com/store/apps/details?id=com.playmetachain.anipangcoins"
                          >
                              <img :src="pre_order_page_new_google_btn" alt="">
                          </a>
                          <a v-if="base.isDomestic"
                             href="#"
                            @click="showDomesticModal"
                          >
                              <img :src="pre_order_page_new_app_btn" alt="">
                          </a>
                          <a v-else
                              href="https://apps.apple.com/app/id6443441145"
                          >
                              <img :src="pre_order_page_new_app_btn" alt="">
                          </a>
                        </span>
                        <img v-if="locale === 'ko'" :src="title_games_lineup_3.ko" alt=""/>
                        <img v-else :src="title_games_lineup_3.en" alt=""/>
                    </span>
                  </div>
              </div>
          </div>
        </div>
      </div>

      <div class="p2e-info section section-cards" >
        <br>
        <br>
        <div class="container" >
          <h1 class="title">
            <img :src="image_head_title"
                 alt="story">
          </h1>
          <h4 class="sub-title">
            {{language.howto_pne_1}}<br>
            {{language.howto_pne_2}}<br>
            {{language.howto_pne_3}}
          </h4>
          <div class="content">
            <img v-if="locale === 'ko'" :src="sections.p2e_info.info_ko" alt=""/>
            <img v-else :src="sections.p2e_info.info_en" alt=""/>
          </div>
        </div>
      </div>



      <!-- modal-modal-error-domestic --------------------------------->
      <div
          class="modal modal-error-domestic modal-bg"
          :class="{ show: isShowDomesticModal }"
      >
        <modal
            v-if="isShowDomesticModal"
            @close="isShowDomesticModal = false"
        >
          <template slot="header">
            <md-button class="md-simple md-close md-just-icon md-round modal-default-button"
                       @click="isShowDomesticModal = false">
              <md-icon>clear</md-icon>
            </md-button>
          </template>

          <template slot="body">
            <h4 class="title">
              {{language.common_toast_popup_title}}
            </h4>
            <br><br>
          </template>
        </modal>
      </div>
      
      
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { Modal } from "@/components";
  import Mixins from "@/plugins/basicMixins";

  export default {
    mixins: [Mixins.HeaderImage],
    components: {
      Modal,
    },
    computed: {
      ...mapGetters([
        'base',
        'locale',
        'language',
      ]),
    },
    bodyClass: "ac-games-pre-order",
    methods: {
      showDomesticModal() {
        this.isShowDomesticModal = true;
      },
      hideDomesticModal() {
        this.isShowDomesticModal = false;
      },
      
    },
    data() {
      return {
        // image_head_bg: require("@/assets/img/pages/games/blast/website_blast_main_1600x900.jpg"),
        isShowDomesticModal: false,

        image_head_title: require("@/assets/img/pages/games/preregister/howtoplay_earn.png"),

        pre_order_page_app_btn: require("@/assets/img/pages/games/common/pre_order_page_app_btn.png"),
        pre_order_page_google_btn: require("@/assets/img/pages/games/common/pre_order_page_google_btn.png"),

        pre_order_page_coins: require("@/assets/img/pages/games/preregister/pre_order_page_coins.png"),
        pre_order_page_coins_bt_text: require("@/assets/img/pages/games/preregister/pre_order_page_coins_bt_text.png"),
        pre_order_page_coins_text: require("@/assets/img/pages/games/preregister/pre_order_page_coins_text.png"),
        launching_page_coins: require("@/assets/img/pages/games/preregister/launching_coins.png"),
        launching_page_coins_text: require("@/assets/img/pages/games/preregister/launching_coins_text.png"),

        pre_order_page_match: require("@/assets/img/pages/games/preregister/pre_order_page_match.png"),
        pre_order_page_match_bt_text: require("@/assets/img/pages/games/preregister/pre_order_page_match_bt_text.png"),
        pre_order_page_match_text: require("@/assets/img/pages/games/preregister/pre_order_page_match_text.png"),
        launching_page_match: require("@/assets/img/pages/games/preregister/launching_match.png"),
        launching_page_match_text: require("@/assets/img/pages/games/preregister/launching_match_text.png"),


        pre_order_page_blast: require("@/assets/img/pages/games/preregister/pre_order_page_touch.png"),
        pre_order_page_blast_bt_text: require("@/assets/img/pages/games/preregister/pre_order_page_blast_bt_text.png"),
        pre_order_page_blast_text: require("@/assets/img/pages/games/preregister/pre_order_page_touch_text.png"),
        launching_page_blast: require("@/assets/img/pages/games/preregister/launching_blast.png"),
        launching_page_blast_text: require("@/assets/img/pages/games/preregister/launching_blast_text.png"),
        

        sections: {
          intro: {
            phone: require("@/assets/img/pages/games/blast/phone.png"),
          },
          p2e_info: {
            info_en: require("@/assets/img/pages/games/preregister/pneplay_en.png"),
            info_ko: require("@/assets/img/pages/games/preregister/pneplay_ko.png"),
          },
        },

        title_games: require("@/assets/img/pages/games/preregister/title_games.png"),
        title_games_lineup_1: {
          ko: require("@/assets/img/pages/games/preregister/card_01_match_ko.png"),
          en: require("@/assets/img/pages/games/preregister/card_01_match_en.png"),
        },
        title_games_lineup_2: {
          ko: require("@/assets/img/pages/games/preregister/card_02_blast_ko.png"),
          en: require("@/assets/img/pages/games/preregister/card_02_blast_en.png"),
        },
        title_games_lineup_3: {
          ko: require("@/assets/img/pages/games/preregister/card_03_coins_ko.png"),
          en: require("@/assets/img/pages/games/preregister/card_03_coins_en.png"),
        },

        pre_order_page_new_app_btn: require("@/assets/img/pages/games/common/btn_apple.png"),
        pre_order_page_new_google_btn: require("@/assets/img/pages/games/common/btn_google.png"),
        pre_order_page_new_home_btn: require("@/assets/img/pages/games/common/btn_home.png"),

      };
    }
  };
</script>

<style lang="scss">

.intro section section-cards h1.title{
    margin-bottom: 24px;
}

  @font-face {
    font-family: 'yg-jalnan';
    src: url('../../assets/fonts/Jalnan.ttf') format('truetype'); /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
    font-weight: normal;
    font-display: 'auto';
    font-style: normal;
  }

  $ac-section-bg: #0085ec !default;
  $sc-font-color: #00589c !default;

  .ac-games-pre-order {

    .pre-order-item {
      border-radius: 25px;
      background-color: white;
      position: relative;
      text-align: center;
      margin-top: 50px;

      .pre-order-header {


        img {
          width: 100%;
          margin: -30px auto 0;
          display: inline-block;
          border-top-left-radius: 25px;
          border-top-right-radius: 25px;
        }

      }

      .pre-order-link-wrap {
        .pre-order-game-intro-links {
          margin-bottom: 28px;
          padding: 12px 20px;
          display: inline-block;
          outline: none;
          cursor: pointer;
          text-align: center;
          text-decoration: none;
          font-family: 'yg-jalnan';
          color: white;
          font-size: 19px;
          text-shadow: 0 1px 1px rgba(0,0,0,.3);
          -webkit-border-radius: 5rem;
          -moz-border-radius: 5rem;
          border-radius: 5rem;
          border: 5px solid #fff;
          -webkit-box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);
          box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);
          &:hover {
            color: white !important;
          }
        }
      }

      .pre-order-content {
        padding: 20px;

        span {
          display: block;
          margin: 0 10%;
          font-size: 15px;
          font-weight: 500;
        }
      }

      .pre-order-game-down-links {
        display: flex;
        justify-content: center;
        padding: 0 10px 20px;

        a {
          display: block;
          width: 50%;
          text-align: center;

          img {
            width: 90%;
          }
        }
      }

      &.match {
        .pre-order-header {
          background-color: #5aad4f;
        }
        .pre-order-content {
          color: #3d9731;
        }
        .pre-order-game-intro-links {
          background-color: #2b8f1d !important;
        }
      }

      &.blast {
        .pre-order-header {
          background-color: #77c4ff;


        }
        .pre-order-content {
          color: #59b5ff;
        }
        .pre-order-game-intro-links {
          background-color: #007ee2 !important;
        }
      }

      &.coins {
        .pre-order-header {
          background-color: #3e3bd2;


        }
        .pre-order-content {
          color: #7f7dbe;
        }
        .pre-order-game-intro-links {
          background-color: #2621c3 !important;
        }
      }


    }

    .p2e-info {
      .title {
        text-align: center;
        margin-bottom: 24px;
      }
      .sub-title {
        color: white;
        text-align: center;
        padding-bottom: 40px;
        margin: 0 30px;
        word-wrap: break-word;
      }
      .content {
        margin-bottom: 40px;
        text-align: center;
      }
    }
    .section {
      background-image: url("../../assets/img/pages/games/preregister/03_pne_bg.png");
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: cover;
      background-color: #0d083b;

      .section-description {
        text-shadow: 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white;
        -webkit-font-smoothing: antialiased;

        .game-title {
          font-family: 'yg-jalnan';
          color: $ac-section-bg;
          font-size: min(max(100%, 10vw), 300%);
        }
        .game-description {
          color: $ac-section-bg;
          font-size: min(max(50%, 5vw), 150%);
        }
      }
    }
  }

  .modal-error-domestic {
    &.show {
      padding-top: 230px;

      /*background-color: rgba(0, 0, 0, .5);*/
    }
    .modal-container {
      background: #0c0a26;
      border-radius: 10px;
      box-shadow: 0 4px 25px 0 rgb(104 37 204 / 80%);
      max-width: 500px;
      margin: 30px auto 30px;


      .modal-header {
        display: none;
      }

      .modal-body {
        padding: 0;
        margin:0 auto;

        .title {
          background-color: #9d5bff;
          color: #0a0c26! important;
          border-radius: 10rem;
          font-size: 17px;
          font-weight: 800;
          display: inline-block;
          margin-top: 30px;
          margin-bottom: 5px;
          padding: 2px 10px 1px;
        }

        .todo-lists {
          list-style-position: inside;
          text-align: left;
          margin: 30px;
          padding: 0;

          .items {
            padding: 0;
            color: #cecece;
          }
        }

      }

      .modal-footer {
        .md-button {
          background-color: #28e5cc !important;
        }
      }
    }
  }

  .sub-title.text-mod2 {
      color: #CFCEF4;
      font-weight: 500;
      margin-top: 20px;
  }

  .sub-title.text-mod2 strong {
      color: #ffffff;
      font-weight: 800;
  }

  .p2e-info h1.title {
      margin-bottom: 0;
  }

  .p2e-info h4.sub-title {
      color: #CFCEF4 !important;
      margin-top: 0;
      margin-bottom: 54px !important;
  }

  .container.layout-mod {
      text-align: center !important;
      max-width: 1300px !important;
  }

  .game_list {
      display: flex;
      justify-content: center;
  }

  .intro.section.section-cards .steps-wrap .md-layout-item {
      width: 349px;
      height: 535px;
      padding-right: 0;
      padding-left: 0
  }

  .steps-wrap .md-layout-item.md-small-size-50 .plan {
      width: 70%;
      height: 70%;
      position: relative
  }

  .steps-wrap .md-layout-item .plan {
      width: 100%;
      height: 100%;
      position: relative;
  }

  .steps-wrap .md-layout-item .coloured-img {
      display: block;
      position: absolute;
      width: auto;
  }

  .intro.section.section-cards .coloured-img {
      display: inline-block;
  }

  .intro.section.section-cards .md-layout {
      margin-top: 56px;
  }

  .intro.section.section-cards .steps-wrap.md-layout {
      width: 90%;
      margin: 0 auto;
      margin-top: 56px;
  }

  .intro.section.section-cards .steps-wrap .md-layout-item {
      display: inline-block;
      width: 349px;
      height: 535px;
      margin-right: 0 !important;
  }

  .steps-wrap .md-layout-item .plan-2 {
      left: 20px;
      top: -20px;
      -webkit-animation-name: Floatingy;
      -moz-animation-name: Floatingy;
      -webkit-animation-duration: 5s;
      -moz-animation-duration: 5s
  }

  .steps-wrap .md-layout-item .plan-3 {
      left: 20px;
      top: 0;
      -webkit-animation-name: Floatingy1;
      -moz-animation-name: Floatingy1;
      -webkit-animation-duration: 4s;
      -moz-animation-duration: 4s
  }

  .steps-wrap .md-layout-item .plan-4 {
      left: 20px;
      top: 10px;
      -webkit-animation-name: Floatingy;
      -moz-animation-name: Floatingy;
      -webkit-animation-duration: 6s;
      -moz-animation-duration: 6s
  }

  .coloured-img {
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease-in-out;
      -moz-animation-iteration-count: infinite;
      -moz-animation-timing-function: ease-in-out
  }

  @-webkit-keyframes Floatingy {
      0% {
          -webkit-transform: translate(0)
      }

      65% {
          -webkit-transform: translateY(20px)
      }

      to {
          -webkit-transform: translate(0)
      }
  }

  @-webkit-keyframes Floatingy1 {
      0% {
          -webkit-transform: translate(0)
      }

      65% {
          -webkit-transform: translateY(25px)
      }

      to {
          -webkit-transform: translate(0)
      }
  }

  @-webkit-keyframes Floatingy2 {
      0% {
          -webkit-transform: translate(0)
      }

      65% {
          -webkit-transform: translateY(30px)
      }

      to {
          -webkit-transform: translate(0)
      }
  }

  section-cards .coloured-img {
      position: relative;
  }

  .appmarket {
      position: absolute;
      z-index: 999;
      bottom: 263px;
      left: 50%;
      width: 100%;
      font-size: 0;
      transform: translateX(-50%);
  }

  .appmarket+img {
      width: 340px;
  }


  .appmarket a {
      display: inline-block;
      margin-left: 8px;
  }

  .appmarket a:nth-last-of-type(1) {
      margin-left: none;
  }

  .appmarket a img {
      width: 45px;
  }

  .ac-roadmap .wrapper .benefits-wrap {
      background-color: none !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
  }

  .intro.section.section-cards h1.title img {
    height: 58px !important;
  }

  .appmarket a:hover img {
    -webkit-filter: brightness(140%);
    filter: brightness(140%);
  }

  @media (max-width: 1120px) {
      .appmarket {
          bottom: 51%;
      }
      .intro.section.section-cards .steps-wrap.md-layout .md-layout-item:nth-of-type(1) .appmarket,
      .intro.section.section-cards .steps-wrap.md-layout .md-layout-item:nth-of-type(2) .appmarket {
          bottom: 54%;
      }
  }

  @media (max-width: 992px) {
      .appmarket+img {
          width: 100%;
      }

      .intro.section.section-cards .steps-wrap .md-layout-item {
          width: 100%;
          height: auto;
          margin-right: 0 !important;
          margin-bottom: 40px;
      }

      .steps-wrap .md-layout-item .plan-2 {
          left: 0;
          top: 0;
          -webkit-animation-name: none;
          -moz-animation-name: none;
      }

      .steps-wrap .md-layout-item .plan-3 {
          left: 0;
          top: 0;
          -webkit-animation-name: none;
          -moz-animation-name: none;
      }

      .steps-wrap .md-layout-item .plan-4 {
          left: 0;
          top: 0;
          -webkit-animation-name: none;
          -moz-animation-name: none;
      }

      .intro.section.section-cards .steps-wrap .md-layout-item .coloured-img {
          position: static;
      }

      .appmarket {
          bottom: 48%;
      }

      .appmarket a {
          width: 15%;
      }

      .appmarket a img {
          width: 100%;
      }

      .appmarket a {
          display: inline-block;
          margin-left: 16
              px;
      }

      .intro.section.section-cards h1.title img {
          height: 58px;
          margin-top: 50px;
      }

      .intro.section.section-cards .steps-wrap.md-layout .md-layout-item:nth-of-type(1) .appmarket,
      .intro.section.section-cards .steps-wrap.md-layout .md-layout-item:nth-of-type(2) .appmarket {
              bottom: 52%;
      }

      .ac-games-pre-order .intro.section.section-cards {
        padding-bottom: 0;
      }

      .intro.section.section-cards .steps-wrap.md-layout .md-layout-item:nth-of-type(1) {
        margin-bottom: 56px;
      }

  }
  
</style>